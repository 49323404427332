
  import { defineComponent } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { routesNames, router } from '@/router';

  export default defineComponent({
    name: 'Dashboard',

    components: { AppIcon },

    setup() {
      const tabs = [
        { label: 'Schools', routeName: routesNames.adminSchools, icon: 'school' },
        { label: 'Categories', routeName: routesNames.adminCategories, icon: 'burger' },
        { label: 'Admins', routeName: routesNames.admins, icon: 'users' }
      ];

      return { tabs, router };
    }

  });
