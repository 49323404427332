<template>
  <div class="bg-white rounded-5 p-25 min-h-390">
    <div class="flex items-center mb-25">
      <AppIcon
        name="settings"
        size="30"
        class="text-blue-ap-100 mr-20"
      />
      <p class="text-2xl">Settings</p>
    </div>

    <div class="flex justify-center items-center">
      <div class="flex gap-20 flex-wrap">
        <div 
          v-for="tab of tabs" 
          :key="tab.label" 
          class="card bg-white py-25 flex flex-col items-center cursor-pointer w-315" 
          @click="router.push({ name: tab.routeName })"
        >
          <div class="icon h-80 w-80 flex items-center justify-center bg-blue-ap-60 rounded-half mb-15">
            <AppIcon
              :name="tab.icon"
              size="38"
              class="text-blue-ap-100"
            />
          </div>
          <p class="text-1xl text-center text-blue-ap-100 font-bold truncate w-full uppercase">
            {{ tab.label }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { routesNames, router } from '@/router';

  export default defineComponent({
    name: 'Dashboard',

    components: { AppIcon },

    setup() {
      const tabs = [
        { label: 'Schools', routeName: routesNames.adminSchools, icon: 'school' },
        { label: 'Categories', routeName: routesNames.adminCategories, icon: 'burger' },
        { label: 'Admins', routeName: routesNames.admins, icon: 'users' }
      ];

      return { tabs, router };
    }

  });
</script>

<style lang="scss" scoped>
  .card {
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.05);
  }

  .card {

    &:hover {
      box-shadow: 0 4px 15px 5px rgba(0, 0, 0, 0.05);

      .icon {
        box-shadow: 0 4px 15px 5px rgba(0, 0, 0, 0.05);
      }
    }
  }
</style>